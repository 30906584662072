const requiredMessage = 'This field is required';

export const financeErrorValidator = {
  accountBalance: generateError('required', requiredMessage),
  accountName: generateError('required', requiredMessage),
  accountNumber: generateError('required', requiredMessage),
  accountType: generateError('required', requiredMessage),
  amount: generateError('required', requiredMessage),
  apAccountId: generateError('required', requiredMessage),
  checkNumber: generateError('required', requiredMessage),
  customerId: generateError('required', requiredMessage),
  date: generateError('required', requiredMessage),
  depositAccountId: generateError('required', requiredMessage),
  description: generateError('required', requiredMessage),
  discountDays: generateError('required', requiredMessage),
  discountInterest: generateError('required', requiredMessage),
  dueDate: generateError('required', requiredMessage),
  dueDays: generateError('required', requiredMessage),
  itemName: generateError('required', requiredMessage),
  itemType: generateError('required', requiredMessage),
  parentAccount: generateError('required', requiredMessage),
  payeeVendorId: generateError('required', requiredMessage),
  paymentAccount: generateError('required', requiredMessage),
  paymentDate: generateError('required', requiredMessage),
  paymentMethod: generateError('required', requiredMessage),
  paymentMethodId: generateError('required', requiredMessage),
  paymentName: generateError('required', requiredMessage),
  price: generateError('required', requiredMessage),
  reference: generateError('required', requiredMessage),
  referenceNumber: generateError('required', requiredMessage),
  routingName: generateError('required', requiredMessage),
  termName: generateError('required', requiredMessage),
  termsId: generateError('required', requiredMessage),
  totalAmount: generateError('required', requiredMessage),
  type: generateError('required', requiredMessage),
  vendorId: generateError('required', requiredMessage)
};

function generateError(type: string, message?: string) {
  return message ? [{ type, message }] : [{ type }];
}
