<mat-form-field appearance="outline" class="radius-10 label-field white-bg w-100"
                [ngClass]="{
                  'invalid': ctr && ctr.invalid,
                  'ng-touched': ctr && ctr.touched,
                  disabled: ctr && ctr.disabled
                }"
>
    <mat-label>{{ name }}</mat-label>
    <input matInput [matDatepicker]="picker" (dateChange)="onValueChange($event)" [(ngModel)]="currentValue" />
    <mat-datepicker #picker [calendarHeaderComponent]="calendarHeader"></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="picker">
    </mat-datepicker-toggle>
</mat-form-field>

<div class="mat-error">
    <span *ngFor="let validator of validators[ctrName]">
        {{ validator.message }}
    </span>
</div>
